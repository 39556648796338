import mainLayout from "@/layout/mainLayout.vue"

const storageRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/filesList/:folder_id",
    name: "filesList",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/files/filesList.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/files/edit",
    name: "editFileLog",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/files/editFileLog.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/folders",
    name: "folders",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/files/folders.vue")
  }
]

export default storageRoutes
