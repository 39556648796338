import mainLayout from "@/layout/mainLayout.vue"

const meetingRoute = [
  {
    path: "/entities/:entity_id/space/:space_id",
    meta: {
      layout: mainLayout,
      authGard: true
    },
    component: () => import("@/views/space/spaceLayout.vue"),
    children: [
      {
        path: "meeting",
        name: "meeting",

        meta: {
          layout: mainLayout,
          authGard: true
        },
        component: () => import("@/views/space/meetings/meeting.vue")
      }
    ]
  }
]

export default meetingRoute
