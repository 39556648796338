import { defineStore } from "pinia"
import { ai } from "@/plugins/axios"
import { useLayoutStore } from "@/store/layout"

// const { setColor } = useLayoutStore()

export const useSpaceStore = defineStore({
  id: "Space",
  state: () => ({
    spacesInfo: [],
    breadcrumbs: []
  }),
  actions: {
    async getSpaceInfo(spaceId) {
      try {
        const { data } = await ai.get(
          `method/agenda.api.space.space.get_space_details`,
          { params: { space_id: spaceId } }
        )
        this.spacesInfo = data
        const { setColor } = useLayoutStore()
        setColor(data.color)
      } catch (err) {
        console.log(err)
      }
    },
    async updateBreadcrumbs(links) {
      console.log(links)
      this.breadcrumbs = links
    }
  }
})
