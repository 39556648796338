import mainLayout from "@/layout/mainLayout.vue"

const recommendationsRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/recommendations",
    name: "recommendationsList",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/recommendations/List.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/recommendations/view/:recommendation_id",
    name: "viewRecommendation",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/recommendations/View.vue")
  },

  {
    path: "/entities/:entity_id/space/:space_id/recommendations/edit/:recommendation_id",
    name: "editRecommendation",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/recommendations/edit.vue")
  }
]

export default recommendationsRoutes
