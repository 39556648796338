import mainLayout from "@/layout/mainLayout.vue"

const discustionRoutes = [
  {
    path: "/entities/:entity_id/space/:space_id/discussions",
    name: "discussionsList",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/discussions/List.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/discussions/add",
    name: "addDiscussions",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/discussions/add.vue")
  },
  {
    path: "/entities/:entity_id/space/:space_id/discussions/details/:discussion_id",
    name: "discussionsDetails",
    meta: {
      layout: mainLayout,
      authGard: true
    },

    component: () => import("@/views/space/discussions/discussionsDetails.vue")
  }
]

export default discustionRoutes
